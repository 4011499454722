import React from "react"
// import { Link } from "gatsby"
// import Image from "gatsby-image"
import experienceBg from "../assets/experience-bg.png"
import experienceYear from "../assets/experience-year.png"

// import { graphql, useStaticQuery } from "gatsby"

const Experience = () => {
  // const {
  //   file: {
  //     childImageSharp: { fluid },
  //   },
  // } = useStaticQuery(query)

  return (
    <div
      style={{
        backgroundImage: `url("${experienceBg}")`,
        backgroundPosition: "left top",
        // backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
      <div className="container container-pad max-w-screen-xl mx-auto my-4 sm:mb-0 sm:mt-8">
        <div className="z-10 overflow-hidden bg-contain exp-bg">
          {/* <img src={experienceBg} /> */}
          {/* <div className="v-center text-center "> */}
          <div className="text-center pb-2 sm:pb-6 lg:pb-16">
            <div className="container max-w-screen-xl flex mx-auto flex-wrap py-5 sm:py-10">
              <div className="exp-1 w-full sm:w-1/5 xl:w-1/4">
                <img
                  alt=""
                  className="mx-auto w-40 sm:w-auto sm:ml-auto mb-5 sm:mb-0"
                  src={experienceYear}
                />
              </div>

              <div className="exp-divider bg-sand hidden sm:block mx-12"></div>

              <div className="w-full text-center sm:text-left sm:w-3/5 lg:w-2/7 text-sm">
                <div className="text-3xl sm:text-3xl 2xl:text-5xl font-black leading-9">
                  ЛЕТ
                </div>
                <div className="text-3xl sm:text-3xl 2xl:text-5xl">
                  ОПЫТА РАБОТЫ
                </div>
                <div className="leading-4 text-justify mt-2 sm:mt-0">
                  На отечественном рынке компания работает{" "}
                  <b>с&nbsp;2001&nbsp;года</b>. Начав свой путь с программных
                  решений в сфере информационных технологий пройден путь от
                  решения локальных задач по автоматизации отдельных бизнес- и
                  технологических процессов до разработки и внедрения
                  полномасштабных проектов по оснащению зданий и сооружений
                  инженерными системами.
                </div>
              </div>

              <div className="bg-sand hidden lg:block exp-divider mx-12"></div>

              <div className="flex-1 w-full mt-6 lg:mt-0 max-w-xs mx-auto">
                <div className="flex">
                  <div className="flex-1">
                    <div className="exp-number font-medium">126</div>
                    <div className="leading-4 mt-3">реализованных проектов</div>
                  </div>

                  <div className="block exp-numb-divider-1 mx-2 xl:mx-12"></div>

                  <div className="flex-1">
                    <div className="exp-number font-medium">4</div>
                    <div className="leading-4 mt-3">
                      региона
                      <br />
                      <b>России</b>
                    </div>
                  </div>
                </div>

                <div className="block w-full exp-numb-divider-2 mt-6"></div>

                <div className="exp-number mt-8 font-medium">&gt;50</div>
                <div className="leading-4 mt-3">сотрудников</div>
                {/* <div className="flex mt-10">
                <div className="exp-number font-medium">&gt;50</div>
                <div className="leading-4 mt-3">сотрудников</div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Experience
