import React, { Component } from "react"
import Slider from "react-slick"
import { Link } from "gatsby"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./SimpleSlider.css"

import slide1 from "../assets/slides/slide-1.jpg"
import slide2 from "../assets/slides/slide-2.jpg"
import slide3 from "../assets/slides/slide-3.jpg"

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      fade: true,
      // arrows: false,
    }

    const slidesData = [
      {
        id: 1,
        src: `${slide1}`,
        title: "РИШОН",
        text: "инжиниринговая компания полного цикла",
      },
      {
        id: 2,
        src: `${slide2}`,
        title: "РИШОН",
        text: "инжиниринговая компания полного цикла",
      },
      {
        id: 3,
        src: `${slide3}`,
        title: "РИШОН",
        text: "инжиниринговая компания полного цикла",
      },
    ]

    return (
      <div className="mx-auto slider-wrapper">
        {/* <div className="max-w-screen-2xl mx-auto"> */}
        <Slider {...settings}>
          {slidesData.map(slide => (
            <div className="relative z-10" key={slide.id}>
              {/* <img className="mx-auto" src={slide.src} /> */}
              <div
                className="slider-image"
                style={{ backgroundImage: `url(${slide.src})` }}
              ></div>
              <div className="v-center text-center absolute text-white">
                <div className="hidden lg:block sm:text-6xl md:text-8xl font-bank-gothic">
                  {slide.title}
                </div>
                <div className="text-xl md:text-2xl lg:text-3xl px-12">
                  {slide.text}
                </div>
                <Link
                  to="/projects/"
                  className="inline-block bg-sand hover:bg-yellow-600 text-black text-sm sm:text-base my-5 sm:my-12 py-2 sm:py-4 px-8 rounded"
                >
                  ПРОЕКТЫ
                </Link>
              </div>
            </div>
          ))}

          {/* <div className="relative z-10">
            <img className="mx-auto" src={slide1} />
            <div className="v-center text-center absolute text-white">
              <div className="hidden lg:block sm:text-6xl md:text-8xl font-bank-gothic">
                РИШОН
              </div>
              <div className="text-xl md:text-2xl lg:text-3xl">
                инжиниринговая компания полного цикла
              </div>
              <Link
                to="/projects/"
                className="inline-block bg-sand hover:bg-yellow-600 text-black text-sm sm:text-base my-3 sm:my-8 py-2 sm:py-4 px-8 rounded"
              >
                ПРОЕКТЫ
              </Link>
            </div>
          </div>
          <div className="relative z-10">
            <img className="mx-auto" src={slide2} />
            <div className="v-center text-center absolute text-white">
              <div className="hidden lg:block sm:text-6xl md:text-8xl font-bank-gothic">
                РИШОН
              </div>
              <div className="text-xl md:text-2xl lg:text-3xl">
                системный интегратор в сфере автоматизации зданий и сооружений
              </div>
              <Link
                to="/projects/"
                className="inline-block bg-sand hover:bg-yellow-600 text-black text-sm sm:text-base my-3 sm:my-8 py-2 sm:py-4 px-8 rounded"
              >
                ПРОЕКТЫ
              </Link>
            </div>
          </div>
          <div className="relative z-10">
            <img className="mx-auto" src={slide3} />
            <div className="v-center text-center absolute text-white">
              <div className="hidden lg:block sm:text-6xl md:text-8xl font-bank-gothic">
                РИШОН
              </div>
              <div className="text-xl md:text-2xl lg:text-3xl">
                системный интегратор в сфере автоматизации зданий и сооружений
              </div>
              <Link
                to="/projects/"
                className="inline-block bg-sand hover:bg-yellow-600 text-black text-sm sm:text-base my-3 sm:my-8 py-2 sm:py-4 px-8 rounded"
              >
                ПРОЕКТЫ
              </Link>
            </div>
          </div> */}
        </Slider>
      </div>
    )
  }
}
