import React from "react"
// import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SimpleSlider from "../components/SimpleSlider"
import Experience from "../components/Experience"
// import Hero from "../components/Hero"
import Services from "../components/Services"
import Footer from "../components/Footer"
// import Jobs from "../components/Jobs"
import OurProjects from "../components/OurProjects"
// import Blogs from "../components/Blogs"
export default () => {
  return (
    <Layout>
      <SimpleSlider />
      <Experience />
      <OurProjects />
      {/* <Hero /> */}
      <Services />
      <Footer />
    </Layout>
  )
}
// ...GatsbyImageSharpFluid
