import React from "react"
import { Link } from "gatsby"
import { AiOutlinePlusCircle } from "react-icons/Ai"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

export const query = graphql`
  {
    allStrapiProjects(
      sort: { fields: on_home_order, order: ASC }
      filter: { on_home_publish: { eq: true } }
    ) {
      nodes {
        id
        strapiId
        name
        slug
        on_home_order
        description_short
        image_small {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
            fixed {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

// fixed {
//   ...GatsbyImageSharpFixed
// }

const OurProjects = () => {
  const data = useStaticQuery(query)
  const {
    allStrapiProjects: { nodes: projects },
  } = data
  // const [value, setValue] = React.useState(0)
  // const { name } = projects[value]

  // console.log(name)

  var proj1 = []
  proj1[0] = projects[0]
  proj1[1] = projects[2]
  proj1[2] = projects[4]

  var proj2 = []
  proj2[0] = projects[1]
  proj2[1] = projects[3]
  proj2[2] = projects[5]

  console.log(projects)
  console.log(proj1)
  console.log(proj2)

  // var twoCards = ""

  return (
    <>
      <div className="container container-pad max-w-screen-xl  mx-auto">
        <div className="container justify-center flex mx-auto items-center mb-4 sm:mb-8 md:mb-16 leading-none max-w-screen-xl">
          <div className="w-full section-line block"></div>
          <div className="flex-none text-center mx-6 sm:mx-10 text-3xl sm:text-5xl leading-none">
            НАШИ ПРОЕКТЫ
          </div>
          <div className="w-full section-line block"></div>
        </div>
      </div>

      <div className="container container-pad mx-auto max-w-screen-xl mb-8">
        <div className="block sm:flex sm:flex-wrap sm:-ml-3 sm:-mr-3">
          {proj1.map((item, index) => {
            // console.log("item", item.image_small.childImageSharp.fluid)
            // console.log("-----proj1.index", proj1[index].on_home_order)
            // console.log("-----proj1.index", proj1[index].on_home_order)
            // console.log("-----proj1.index", proj2[index].on_home_order)
            // debugger
            return (
              <div
                className="flex flex-col project__card sm:pl-3 sm:pr-3"
                key={item.strapiId}
              >
                <div className="proj-card mt-3 mb-3">
                  <div className="project__post">
                    <BackgroundImage
                      Tag="section"
                      className={`project__img ${
                        proj1[index].on_home_order === 3
                          ? "project__img_big"
                          : ""
                      }`}
                      fluid={proj1[index].image_small.childImageSharp.fluid}
                      backgroundColor={`#1e3a68`}
                    ></BackgroundImage>
                    <div className="top-0 bottom-0 proj-back text-left">
                      <div className="text-xl sm:text-base sm:tracking-normal md:tracking-wide lg:trackin-wider md:text-xl lg:text-xl 2xl:text-2xl mb-2 leading-6 sm:leading-5 md:leading-6">
                        {proj1[index].name}
                      </div>
                      <div className="text-sm lg:block lg:text-sm xl:text-base leading-4_1 tracking-wider">
                        {proj1[index].description_short}
                      </div>
                      <Link
                        to={`/projects/${proj1[index].slug}`}
                        className="link proj-icon"
                      >
                        <div className="project__icon">
                          <AiOutlinePlusCircle />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="proj-card mb-3 mt-3">
                  <div className="project__post">
                    <BackgroundImage
                      Tag="section"
                      className={`project__img ${
                        proj2[index].on_home_order === 2 ||
                        proj2[index].on_home_order === 6
                          ? "project__img_big"
                          : ""
                      }`}
                      fluid={proj2[index].image_small.childImageSharp.fluid}
                      backgroundColor={`#1e3a68`}
                    ></BackgroundImage>
                    <div className="top-0 bottom-0 proj-back text-left">
                      <div className="text-xl sm:text-base sm:tracking-normal md:tracking-wide lg:trackin-wider md:text-xl lg:text-xl 2xl:text-2xl mb-2 leading-6 sm:leading-5 md:leading-6">
                        {proj2[index].name}
                      </div>
                      <div className="text-sm lg:block lg:text-sm xl:text-base leading-4_1 tracking-wider">
                        {proj2[index].description_short}
                      </div>
                      <Link
                        to={`/projects/${proj2[index].slug}`}
                        className="link proj-icon"
                      >
                        <div className="project__icon">
                          <AiOutlinePlusCircle />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="container container-pad container-our-projects max-w-screen-xl flex flex-wrap mx-auto text-sm overflow-x-hidden">
        <div className="mx-auto">
          <Link
            to="/projects/"
            className="inline-block bg-sand hover:bg-yellow-600 text-black text-sm sm:text-base my-3 sm:my-8 py-2 sm:py-4 px-15 rounded"
          >
            ВСЕ ПРОЕКТЫ
          </Link>
        </div>
      </div>
    </>
  )
}
export default OurProjects
