import React from "react"
import service1 from "../assets/services/service-1.png"
import service2 from "../assets/services/service-2.png"
import service3 from "../assets/services/service-3.png"
import service4 from "../assets/services/service-4.png"
// import service5 from "../assets/services/service-5.png"

const Services = () => {
  return (
    <>
      <div className="container container-pad max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-16">
        <div className="container justify-center flex mx-auto items-center mt-12 mb-4 sm:mb-8 md:mb-16 leading-none max-w-screen-xl">
          <div className="w-full section-line"></div>
          <div className="flex-none text-center mx-6 sm:mx-10 text-3xl sm:text-5xl leading-none">
            НАШИ УСЛУГИ
          </div>
          <div className="w-full section-line"></div>
        </div>
      </div>

      <div className="container container-pad max-w-screen-xl justify-center flex mx-auto flex-wrap sm:pb-10">
        <div className="card-service mb-10 w-full sm:w-1/2 lg:w-1/4">
          <img className="mx-10 mx-auto" alt="" src={service1} />
          <div className="text-2xl font-bold m-5 leading-7 text-center">
            Водоснабжение и канализация
          </div>
          <ul className="list-disc list-outside px-3 sm:px-5">
            <li>ХВС (холодное водоснабжение)</li>
            <li>ГВС (горячее водоснабжение)</li>
            <li>Насосные станции</li>
            <li>Системы очистки воды</li>
            <li>Ливневая канализация</li>
            <li>Пожарный водопровод</li>
          </ul>
        </div>
        <div className="card-service mb-10 w-full sm:w-1/2 lg:w-1/4">
          <img className="mx-10 mx-auto" alt="" src={service2} />
          <div className="text-2xl font-bold m-5 leading-7 text-center">
            Системы водяного и электрического теплоснабжения
          </div>
          <ul className="list-disc list-outside px-3 sm:px-5">
            <li>Приточно-вытяжные установки</li>
            <li>Прецензионное кондиционирование</li>
            <li>Обогрев пандусов и водостоков</li>
            <li>Системы кондиционирования</li>
          </ul>
        </div>
        <div className="card-service mb-10 w-full sm:w-1/2 lg:w-1/4">
          <img className="mx-10 mx-auto" alt="" src={service3} />
          <div className="text-2xl font-bold m-5 leading-7 text-center">
            Электромонтажные работы
          </div>
          <ul className="list-disc list-outside px-3 sm:px-5">
            <li>Монтаж кабельных линий</li>
            <li>Монтаж щитового оборудования</li>
            <li>Монтаж электротехнических изделий</li>
            <li>Монтаж систем освещения</li>
          </ul>
        </div>
        <div className="card-service mb-10 w-full sm:w-1/2 lg:w-1/4">
          <img className="mx-10 mx-auto" alt="" src={service4} />
          <div className="text-2xl font-bold m-5 leading-7 text-center">
            Слаботочные системы
          </div>
          <ul className="list-disc list-outside px-3 sm:px-5">
            <li>Системы контроля доступом</li>
            <li>Системы видеонаблюдения</li>
            <li>Охранно-пожарная сигнализация</li>
            <li>Системы оповещения и эвукауции при пожаре</li>
            <li>Системы автоматизации и диспетчеризации, сети связи, СКС</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Services
